.container123 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .wrapper123 {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    overflow: auto;
  }
  
  .close12 {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .error {
    color: red;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  table th,
  table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  table th {
    background-color: #f2f2f2;
  }
  
  .btn2345 {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn2345:hover {
    background-color: #0056b3;
  }
  