.messages {
  padding: 20px;

  h1 {
    font-size: 32px;
    margin-bottom: 20px; 
    text-align: center;
    color: #333;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .wrappermessage {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;

    .cardmessage {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      transition: all 0.3s ease;
      
      .detailsmessage {
        p {
          margin-bottom: 10px;
          font-size: 16px;
          color: #555;
          
          span {
            font-weight: bold;
            color: #333;
            word-wrap: break-word;
            display: inline-block;
            max-width: 100%;
          }
        }
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
      }

      @media (max-width: 768px) {
        min-width: 280px;
        
        .detailsmessage {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
