/* .container {
    width: 100%;
    padding: 20px;
    height: 100vh;
    border: 1px solid #ccc;
    font-family: Arial, sans-serif;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.hospital-details {
    font-size: 18px;
}

.doctor-details {
    width: 45%;
   margin-top: 30px;
}

.patient-details {
    width: 100%;
    margin-top: 20px;
}

.details-title {
    margin-bottom: 10px;
}

.details {
    font-size: 16px;
}

.detail {
    display: inline-block;
    margin-right: 20px;
}

.remarks {
    width: 100%;
    margin-top: 20px;
}

.remark-label {
    display: block;
    margin-bottom: 5px;
}

.remark-input {
    width: 100%;
    height: 100px;
    padding: 5px;
    font-size: 16px;
}

.button123 {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button123:hover {
    background-color: #0056b3;
}

.divider {
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    border-top: 1px solid #ccc;
}


} */

@media print {
    .remarks,
    .button123 {
        display: none;
    }
}