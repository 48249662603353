.navbar1 {
    background-color: #fff; /* Match the background color of the sidebar */
    color: #333; /* Text color */
    padding: 10px 20px;
    height: 70px; /* Set the height to match the sidebar's height */
    hr {
        height: 0;
        border: 0.5px solid rgb(230, 227, 227);
    }
    .wrapper1 {
      display: flex;
      align-items:center;
      justify-content: space-around;
  
      .items {
        display: flex;
        align-items: center;
  
        .item {
          margin-right: 20px;
  
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  
    /* Hide scrollbar */
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none; /* WebKit */
    }

  }
  

  @media screen and (max-width: 400px) {
    .sm-f-24{
        font-size: 24px;
    }

}